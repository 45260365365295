<template>
  <popup-base>
    <div
      class="begin bg-white rounded-md py-3 md:w-4/5 sm:w-10/12 w-11/12 mr-1 ml-5 lg:mx-auto my-10"
      :style="{ minHeight: '250px' }"
    >
      <div class="flex justify-between items-center mb-2 border-b pb-2 px-6">
        <div class="text-2xl text-identityColor font-serif font-bold">
          <span>Achat d'unite</span>
        </div>
        <div
          class="h-9 w-9 flex rounded-md cursor-pointer"
          @click="$emit('close')"
        >
          <img src="@/assets/images/img_close.webp" alt="close" original />
        </div>
      </div>
      <div class="mb-2 px-6">
        <div class="font-bold text-2xl leading-relaxed text-blue-700">
          1 BTC = 100.000.000 SATS
        </div>
        <div
          class="font-semibold text-xl leading-relaxed text-blue-700"
          v-if="price.rate"
        >
          1 BTC = {{ price.rate.toLocaleString() }} XOF
        </div>
      </div>
      <div class="body px-6 overflow-y-scroll h-80 lg:h-full">
        <div class="flex md:flex-row flex-col w-full mb-3">
          <div class="form-content w-full md:mr-7 md:mb-0 mb-3">
            <div class="label font-normal mb-2 text-gray-500">Prénom*</div>
            <div>
              <inputo
                background="transparent"
                height="46px"
                border="1px solid #CDD1D6"
                radius="6px"
                class="w-full border rounded-md"
                :value-t="val"
                @info="(val) => (firstName = val)"
              />
            </div>
          </div>

          <div class="form-content w-full">
            <div class="label font-normal mb-2 text-gray-500">Nom*</div>
            <div class="">
              <inputo
                background="transparent"
                height="46px"
                border="1px solid #CDD1D6"
                radius="6px"
                class="w-full border rounded-md"
                :value-t="val"
                @info="(val) => (lastName = val)"
              />
            </div>
          </div>
        </div>

        <div class="flex md:flex-row flex-col w-full mb-3">
          <div class="form-content w-full lg:mr-0 xl:mr-0 md:mr-7 md:mb-0 mb-3">
            <div class="label font-normal mb-2 text-gray-500">
              Email personnel*
            </div>
            <div class="">
              <inputo
                type="email"
                background="transparent"
                height="46px"
                border="1px solid #CDD1D6"
                radius="6px"
                class="w-full border rounded-md"
                :value-t="val"
                @info="(val) => (email = val)"
              />
            </div>
          </div>
        </div>
        <div class="flex md:flex-row flex-col w-full mb-3">
          <div class="form-content w-full lg:mr-0 xl:mr-0 md:mr-7 md:mb-0 mb-3">
            <div class="flex justify-between items-center mb-2">
              <div class="label font-normal mb-2 text-gray-500">
                <span>Montant d'unite à recevoir en</span>
                <span class="text-identityColor"> Xof*</span>
              </div>
            </div>
            <div class="">
              <input
                class="w-full border p-3 rounded-md"
                type="number"
                v-model="inputAmountXof"
                @input="calculateAmountSat"
                @keypress="isNumberOnly"
                placeholder="Montant en xof"
              />
            </div>
          </div>
        </div>
        <div class="flex md:flex-row flex-col w-full mb-2">
          <div class="form-content w-full lg:mr-0 xl:mr-0 md:mr-7 md:mb-0 mb-3">
            <div class="flex justify-between items-center mb-2">
              <div class="label font-normal mb-2 text-gray-500">
                <span>Montant à envoyer en</span>
                <span class="text-identityColor"> Sats*</span>
              </div>
            </div>
            <div class="">
              <input
                class="w-full border p-3 rounded-md"
                type="number"
                v-model="inputAmountSats"
                @input="calculateAmountXof"
                @keypress="isNumberOnly"
                placeholder="Montant en sats"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="border-b mt-5 mb-6"></div>
      <div class="px-6">
        <div class="flex justify-end">
          <button
            class="border rounded-md py-3 md:px-10 px-5 hover:bg-gray-200 text-gray-400 hover:text-gray-600"
            @click="$emit('close')"
          >
            Précédent
          </button>
          <div class="ml-4">
            <boutton
              size="14px"
              background="#ff914d"
              height="40px"
              padding="21px 24px"
              label="Continuer"
              weight="600"
              radius="6px"
              :charge="charge"
              @oga="openActivApprouved()"
            />
          </div>
        </div>
      </div>
    </div>
  </popup-base>
  <confirmation-payment
    :activation="activApprouved"
    v-if="activApprouved"
    :h-one="title"
    :description="message"
    :background="btnBack"
    :boutonType="boutontype"
    :bouton-label="boutonLabel"
    bouton-labell="Annuler"
    boutonWidth="30px"
    boutonWidthh="20px"
    @fermer="closeActivApprouved"
    @continuer="continueActivApprouved"
  />
</template>
<script>
import popupBase from "@/components/helper/add/popup-base.vue";
import inputo from "@/components/helper/form/input";
import close from "@/assets/icons/fermer.svg";
import ConfirmationPayment from "@/components/popups/confirmation-payment.vue";
import boutton from "@/components/helper/add/button";

export default {
  components: { popupBase, inputo, boutton, ConfirmationPayment },
  props: {
    dataFirstForm: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      icons: { close },
      isFiat: true,
      charge: false,
      activConfirmation: false,
      activApprouved: false,
      message:
        "Vous êtes sur le point de valider cette transaction, souhaitez-vous continuer ?",
      boutonLabel: "Confirmer",
      boutonType: null,
      btnBack: "#FF914D",
      title: "Payement",
      data: null,
      firstName: "",
      lastName: "",
      email: "",
      inputAmountXof: "",
      inputAmountSats: "",
      price: {},
      totalSatsToReceive: null,
      totalXofToReceive: null,
      btcToSats: 100000000,
      flashPercentage: 0.01,
    };
  },

  created() {
    this.getSingleSymbolPrice();
    this.calculateAmountSat();
    this.calculateAmountXof();
  },

  methods: {
    calculateAmountSat() {
      let price = this.price.rate;
      if (this.inputAmountXof) {
        this.totalSatsToReceive =
          (this.btcToSats * this.inputAmountXof) / price;

        this.inputAmountSats =
          this.totalSatsToReceive * (1 + this.flashPercentage);

        this.inputAmountSats = Math.ceil(this.inputAmountSats);
        // console.log("SAT:", this.totalSatsToReceive);
      }
    },

    calculateAmountXof() {
      let price = this.price.rate;
      if (this.inputAmountSats) {
        this.totalXofToReceive =
          (this.inputAmountSats * price) / this.btcToSats;

        this.inputAmountXof =
          this.totalXofToReceive * (1 - this.flashPercentage);

        this.inputAmountXof = Math.floor(this.inputAmountXof);
        // console.log("XOF:", this.totalXofToReceive);
      }
    },

    getSingleSymbolPrice() {
      this.charge = true;
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        // body: JSON.stringify({ conversion: "BTC_XOF", amount: 1 }),
      };

      fetch("https://api.yadio.io/rate/XOF/BTC", options)
        .then((response) => response.json())
        .then((response) => {
          this.price = response;
          // console.log("api Price bitcoin now", this.price);
          this.charge = false;
          this.calculateAmountSat();
          this.calculateAmountXof();
        })
        .catch((err) => {
          // this.charge = false;
          console.log(err);
          this.$toast.err(this.title + "Une erreur s'est produite");
        });
    },

    async openActivApprouved() {
      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        inputAmountXof: this.inputAmountXof,
        inputAmountSats: this.inputAmountSats,
      };
      this.data = data;
      if (this.charge) {
        this.$toast.info("Veillez patienter s'il vous plait !");
      } else {
        // if (this.inputAmountXof < 100) {
        //   this.$toast.info("Le montant minimum est de 100 XOF");
        // } else {
        // this.charge = true;
        if (Object.values(data).every((value) => value !== "")) {
          if (this.inputAmountXof < 100) {
            this.$toast.info("Le montant minimum est de 100 XOF");
          } else {
            // this.charge = false;
            this.$emit("openTransactionModalEndUnite", this.data);
          }
        } else {
          // this.charge = false;
          this.$toast.error("Veillez remplir tous les champs.");
        }
        // }
      }
    },
    // async openActivApprouved() {
    //   const data = {
    //     firstName: this.firstName,
    //     lastName: this.lastName,
    //     email: this.email,
    //     inputAmountXof: this.inputAmountXof,
    //     inputAmountSats: this.inputAmountSats,
    //   };
    //   this.data = data;
    //   if (this.inputAmountXof < 100) {
    //     this.$toast.info("Le montant minimum est de 100 XOF");
    //   } else {
    //     this.charge = true;
    //     if (Object.values(data).every((value) => value !== "")) {
    //       if (this.inputAmountXof < 100) {
    //         this.$toast.info("Le montant minimum est de 100 XOF");
    //       } else {
    //         this.charge = false;
    //         this.$emit("openTransactionModalEndUnite", this.data);
    //       }
    //     } else {
    //       this.charge = false;
    //       this.$toast.error("Veillez remplir tous les champs requis");
    //     }
    //   }
    // },

    closeActivApprouved() {
      this.activApprouved = false;
      this.$emit("close");
      this.$emit("closePaymentNumberUnite");
    },

    async continueActivApprouved() {
      this.$emit("closePaymentNumberUnite");

      const result = this.data;
      if (result) {
        this.charge = false;
        this.$emit("close", true);
        this.$toast.success("Commande validée");
        this.$router.push({ path: "/succed" });
      } else {
        this.charge = false;
        this.$toast.error("Nous rencontrons des problèmes.");
        this.$emit("close", true);
      }
    },
    isNumberOnly(event) {
      const keyCode = event.keyCode || event.which;
      const isAllowedKey = (keyCode >= 48 && keyCode <= 57) || keyCode === 46; // 48 to 57 are numbers, 46 is dot
      const inputValue = String.fromCharCode(keyCode);

      if (!isAllowedKey || /\D/.test(inputValue)) {
        event.preventDefault();
      }
    },
  },
};
</script>
<style lang="scss">
@import "src/assets/style/sass/variables";
div {
  font-family: $font-family;
}
.desc {
  width: 100% !important;
  padding-top: 28px !important;
}
.begin {
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.12);
}
.body {
  max-height: 30rem;
}
</style>
